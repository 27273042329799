function parseCandidate(line) {
  let parts;
  if (line.indexOf("a=candidate:") === 0) {
    parts = line.substring(12).split(" ");
  } else {
    parts = line.substring(10).split(" ");
  }
  let relatedAddress = null;
  let relatedPort = null;
  let tcpType = null;
  for (let i = 8; i < parts.length; i += 2) {
    switch (parts[i]) {
      case "raddr":
        relatedAddress = parts[i + 1];
        break;
      case "rport":
        relatedPort = parseInt(parts[i + 1], 10);
        break;
      case "tcptype":
        tcpType = parts[i + 1];
        break;
      default:
        break;
    }
  }
  const candidate = {
    foundation: parts[0],
    component: parts[1],
    protocol: parts[2].toLowerCase(),
    priority: parseInt(parts[3], 10),
    ip: parts[4],
    port: parseInt(parts[5], 10),
    type: parts[7],
    relatedAddress,
    relatedPort,
    tcpType
  };
  return candidate;
}
export const isNormalNat = () => new Promise((resolve, reject) => {
  const candidates = {};
  const pc = new RTCPeerConnection({
    iceServers: [
      {urls: "stun:stun1.l.google.com:19302"},
      {urls: "stun:stun2.l.google.com:19302"}
    ]
  });
  pc.createDataChannel("foo");
  pc.onicecandidate = function(e) {
    if (e.candidate && e.candidate.candidate.indexOf("srflx") !== -1) {
      const cand = parseCandidate(e.candidate.candidate);
      const {relatedPort, port} = cand;
      if (relatedPort !== null && relatedPort !== void 0) {
        if (!candidates[relatedPort]) {
          candidates[relatedPort] = [];
        }
        if (port !== null && port !== void 0) {
          candidates[relatedPort].push(port);
        }
      }
    } else if (!e.candidate) {
      if (Object.keys(candidates).length === 1) {
        const ports = candidates[Object.keys(candidates)[0]];
        resolve(ports.length === 1);
      }
    }
  };
  pc.createOffer().then((offer) => pc.setLocalDescription(offer));
});
